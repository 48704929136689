$(document).ready(function () {
  $(".review-slider").slick({
    arrows: true,
    autoplay: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<div  class="slick-prev"></div>',
    nextArrow: '<div  class="slick-next"></div>',
  });

  $(".animated, .flash ").viewportChecker({
    classToAdd: "visible",
    offset: 100,
  });

  $(".topmenu a[href^='#']").click(function () {
    var _href = $(this).attr("href");
    $("html, body").animate({ scrollTop: $(_href).offset().top + "px" });
    return false;
  });
});
